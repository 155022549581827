var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('base-input',{staticClass:"mb-sm relative login__input",attrs:{"input-class":'bg-grey-5',"type":"email","name":"email","focus":"","placeholder":_vm.$t('Email'),"validations":[
              {
                condition: !_vm.$v.email.required && _vm.$v.email.$error,
                text: _vm.$t('Field is required.')
              },
              {
                condition: !_vm.$v.email.email && _vm.$v.email.$error,
                text: _vm.$t('Please provide valid e-mail address.')
              }
            ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}},[_c('i',{staticClass:"ss-gizmo ss-user text-brown-2 absolute z-1"})]),_vm._v(" "),_c('base-input',{staticClass:"mb-sm relative login__input",attrs:{"input-class":'bg-grey-5',"type":"password","name":"password","placeholder":_vm.$t('Password'),"validations":[{
              condition: !_vm.$v.password.required && _vm.$v.password.$error,
              text: _vm.$t('Field is required.')
            }]},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_c('i',{staticClass:"ss-gizmo ss-key text-brown-2 absolute z-1"})]),_vm._v(" "),_c('button-full',{staticClass:"login__submit relative mb-5",attrs:{"type":"submit"}},[_vm._v("\n      "+_vm._s(_vm.$t('Sign In'))+"\n      "),_c('i',{staticClass:"ss-gizmo ss-right absolute top-0 text-h5"})])],1),_vm._v(" "),_c('router-link',{staticClass:"login__link text-white text-average",attrs:{"to":_vm.localizedRoute('/customer/account/forgotpassword'),"title":_vm.$t('Forgot Your Password?')}},[_vm._v("\n    "+_vm._s(_vm.$t('Forgot Your Password?'))+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }