<template>
  <div class="login">
    <form @submit.prevent="login" novalidate>
      <base-input
        class="mb-sm relative login__input"
        :input-class="'bg-grey-5'"
        type="email"
        name="email"
        focus
        v-model="email"
        @blur="$v.email.$touch()"
        :placeholder="$t('Email')"
        :validations="[
                {
                  condition: !$v.email.required && $v.email.$error,
                  text: $t('Field is required.')
                },
                {
                  condition: !$v.email.email && $v.email.$error,
                  text: $t('Please provide valid e-mail address.')
                }
              ]"
      >
        <i class="ss-gizmo ss-user text-brown-2 absolute z-1"></i>
      </base-input>
      <base-input
        class="mb-sm relative login__input"
        :input-class="'bg-grey-5'"
        type="password"
        name="password"
        v-model="password"
        @blur="$v.password.$touch()"
        :placeholder="$t('Password')"
        :validations="[{
                condition: !$v.password.required && $v.password.$error,
                text: $t('Field is required.')
              }]"
      >
        <i class="ss-gizmo ss-key text-brown-2 absolute z-1"></i>
      </base-input>
      <button-full class="login__submit relative mb-5"
                   type="submit">
        {{ $t('Sign In') }}
        <i class="ss-gizmo ss-right absolute top-0 text-h5"></i>
      </button-full>
    </form>
    <router-link
      :to="localizedRoute('/customer/account/forgotpassword')"
      :title="$t('Forgot Your Password?')"
      class="login__link text-white text-average"
    >
      {{ $t('Forgot Your Password?') }}
    </router-link>
  </div>
</template>

<script>
import { Login } from '@vue-storefront/core/modules/user/components/Login'

import ButtonFull from 'theme/components/theme/ButtonFull'
import BaseCheckbox from '../Form/BaseCheckbox'
import BaseInput from '../Form/BaseInput'
import { required, email } from 'vuelidate/lib/validators'
import i18n from '@vue-storefront/i18n'

export default {
  mixins: [Login],
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  methods: {
    login () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('Please fix the validation errors'),
          action1: { label: this.$t('OK') }
        })
        return
      }
      this.callLogin()
    },
    remindPassword () {
      if (!(typeof navigator !== 'undefined' && navigator.onLine)) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('Reset password feature does not work while offline!'),
          action1: { label: i18n.t('OK') }
        })
      } else {
        this.callForgotPassword()
      }
    },
    onSuccess () {
      this.$bus.$on('user-after-loggedin', res => {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'success',
          message: i18n.t('You are logged in!'),
          action1: { label: i18n.t('OK') }
        })
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push({ name: 'home' })
        }
      })
    },
    onFailure (result) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t(result.result),
        action1: { label: i18n.t('OK') }
      })
    },
    close () {
      return false
    }
  },
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput
  }
}
</script>

<style lang="scss" scoped>
  $brown-2: #8f7b4c;
  .login {
    &__input {
      &::v-deep {
        input {
          @apply bg-white border-0 text-above-md pl-13;
          height: 60px;
        }
        .icon {
          top: 22px;
        }
      }
      .ss-gizmo {
        font-size: 19px;
        height: 60px;
        line-height: 60px;
        left: 15px;
        top: 3px;
        width: 19px;
      }
    }
    &__submit {
      border-color: $brown-2;
      border-width: 1px;
      color: $brown-2;
      line-height: 60px;
      height: 60px;
      padding: 0 40px 0 20px;
      text-align: left;
      transition: background-color .5s ease, border-color .5s ease, color .5s ease;
      width: 170px;
      .ss-gizmo {
        line-height: 62px;
        right: 20px;
      }
      &:hover {
        @apply bg-white border-black text-black;
      }
    }
    &__link {
      transition: color .25s ease;
      &:hover {
        @apply text-brown-2;
      }
    }
  }
</style>
